
.badge {
    visibility: hidden;
}

.badge-button {
    background-color: inherit;
    border: none; 
    background: none; 
    padding: 0; 
    cursor: pointer;
}

.badge-button:focus,
.badge-button:active {
  outline: none;
  border: none;
}

.visible {
    visibility: visible;
}

.close {
    position: absolute;
    top: 0px; 
    right: 5px; 
    padding: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }

.notifications-modal {
    font-size: 12px;
    position: absolute;
    top: calc(100% - 10px); /* Adjust as needed */
    right: calc(-350% - 8px); /* Adjust as needed */
    display: block;
    z-index: 100;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    width: 400px; /* Adjust the width as needed */
}

.modal-dialog {
    margin: 0;
}

.modal-content {
    border: none;
}

.modal-header {
    position: relative;
}

.modal-body {
  padding: 5px;
}