.checkbox_signup label {
  font-size: 22px;
  font-weight: 700;
  line-height: 21px;
  color: #ffffff;
  /* display: none; */
  /* background-color: transparent; */
}

.checkbox_signup input {
  background-color: rgba(255, 255, 255, 0);
}

.checkbox_signup input:checked {
  background-color: white;
  color: transparent;
}

/* .checkbox_signup input:checked::after {
  content: "✓";
} */

.camera_icon {
  font-size: 27px;
  color: #ffffffb2;
  position: absolute;
  z-index: 999;
  top: 48px;
  left: 169px;
}

.for-top-index {
  position: relative;
  z-index: 999;
}

.disabled-div {
  pointer-events: none;
  opacity: 0.6;
}

.signup_grid {
  background-image: url("/public/images/bg-img.png");
  background-size: auto;
  background-position: bottom center;
  background-repeat: no-repeat;
}
.col_divv {
  padding: 40px;
  position: relative;
  background-image: url("/public/images/bg-img.png");
  background-size: auto;
  background-position: bottom center;
  background-repeat: no-repeat;
}

.col_divv::after {
  content: "";
  background-image: url("/public/images/signup-bg.svg");
  position: absolute;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  width: 100%;
  height: 100%;
}

.disabled_class {
  pointer-events: none !important;
  opacity: 0.6 !important;
  color: #ffffff !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
}

.label_discription {
  color: #ffffff !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
}

.MuiTextField-root .form-control {
  border-radius: 10px !important;
}

.signupForm .form-control {
  border-radius: 10px !important;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 4px 0 5px !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 10px !important;
  padding: 0px 15px !important;
  height: 46px !important;
}

.spacify.active {
  background-color: #4264d0;
}

.concern input {
  width: 20px !important;
  height: 20px !important;
}
.spacify {
  border-radius: 10px !important;
}

.form_checkbox .form-check-input[type="checkbox"]:checked {
  background-image: url(../../../public/images/check.png) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.title_width {
  width: max-content !important;
}

.step_titlte {
  font-size: 36px !important;
}

.title_width_text {
  font-size: 36px !important;
  display: flex !important;
  width: max-content !important;
  margin: auto;
  padding-bottom: 20px;
}
@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .form_checkbox {
    margin-bottom: 50px !important;
  }
}
