.dashboard_name {
  color: #18357a;
  font-size: 22px;
  line-height: 21px;
  font-weight: 700 !important;
}

.dashboard_msg {
  color: #5b6680;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.link {
  color: #4264d0 !important;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700 !important;
}

.accept_msg {
  color: #00bb61 !important;
  font-weight: 700 !important;
}

.edit_rsvp {
  color: #4264d0 !important;
  font-weight: 700 !important;
  cursor: pointer;
}

.box_border {
  /* border-left: 6px solid; */
  padding-left: 20px;
}

.fc-day-today {
  background: #fff !important;
  border: none !important;
}

.btn_primary {
  display: flex;
  width: fit-content;
  justify-content: end;
  height: 100%;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: #4264d0;
  cursor: pointer;
  float: right;
  transform: translate(-70px, 0px);
}
.fc-toolbar-title {
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 32.5px !important;
  text-align: left;
  color: #18357a;
  text-transform: capitalize !important;
}

.active-event {
  border-left: 5px solid #007bff; /* Example style */
  background-color: #d1e7dd; /* Example background color */
}

.fc .fc-timegrid-divider {
  padding: 0px;
  display: none;
}

.fc .fc-scrollgrid-section,
.fc .fc-scrollgrid-section table {
  height: 0px;
}

.view_all_btn {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  color: #4264d0 !important;
}

.box_radius {
  border-radius: 10px;
}

.tab_dashboard .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
  background: #f8f9fe;
  border-bottom: 1px solid #d1dbf0;
  height: 94px;
  border-radius: 13px 13px 0px 0px;
  align-items: center;
  padding: 15px;
}

.tab_dashboard .fc-scrollgrid-section-header {
  display: none;
}
