.loginBglight h1 {
  font-weight: 700;
}

.loginBglight p {
  font-size: 14px;
  color: #5b6680;
}

.loginBglight label {
  font-weight: 400;
  color: #8f99b0;
}

.setWidthform {
  width: 100%;
  max-width: 363px;
}

.loginBglight a {
  font-weight: 600;
  color: #4264d0 !important;
  text-decoration: none;
  font-size: 14px;
}

.eysSet {
  right: 12px;
  top: 15px;
}

.btn.btn-primary {
  font-size: 14px;
}

.startRounded {
  border-radius: 40px 0 0 40px;
}

.bgoverlay {
  background-color: #0000004f;
  top: 0;
  left: 0;
}

.popupMax-width {
  width: 600px;
}

.popupMax-width h2 {
  color: #18357a;
  font-weight: 700;
}

.minWidth {
  min-width: 470px;
}

.spacify input {
  width: 100%;
  height: 16px;
}

.spacify label {
  color: #18357a;
  font-size: 16px;
  font-weight: 700;
}

.spacify p {
  font-size: 12px;
}

.spacify.active {
  background-color: #4264d0;
}

.spacify.active label,
.spacify.active p {
  color: #fff;
}

.spacify.active input {
  accent-color: #fff;
}

.back_btn {
  cursor: pointer;
}
.show_pass {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.show_forgot_pass {
  position: absolute;
  right: 0px;
  top: 0px;
  transform: translate(-10px, 10px);
}

.user_link {
  justify-content: center;
  display: flex;
  align-items: center;
}

.input_form_field {
  min-width: 312px;
  height: 46px;
  border-radius: 10px;
}

.form_checkbox .form-check-input[type="checkbox"] {
  border-radius: 3.25em !important;
  min-width: 18px !important;
  min-height: 18px !important;
  /* background: #ffffff !important; */
  border-color: #ffffff !important;
}

.form_checkbox .form-check-input[type="checkbox"]:checked {
  background-image: url(../../../public/images/check.png) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.signupForm .form-control {
  border-radius: 10px !important;
}
