.sidebarMenu {
  width: 80px;
}
.sidebarMenu .nav .nav-link.active {
  background-color: #322e86 !important;
  border-radius: 12px;
}
.sidebarMenu .nav .nav-link {
  font-size: 12.9px;
  font-weight: 400;
}
.sidebarMenu .nav .nav-link.active svg path {
  fill-opacity: 1 !important;
}
.sidebarMenu .nav .nav-link.active::after {
  content: "";
  background-image: url(../../../../public/images/menu-active.svg);
  position: absolute;
  right: -24px;
  display: block;
  height: 86px;
  width: 14px;
  background-repeat: no-repeat;
  top: -14px;
}

.sidebarMenu .nav .nav-link.active span {
  font-size: 12.9px;
  font-weight: 400;
  color: white;
}

.diarycard_text {
  display: "block";
  margin-top: "4px";
  line-height: "15px";
}
