.dbSkillTab .nav-item .nav-link {
  background-color: #f8f9fe;
  color: #5b6680;
  font-size: 12.9px;
  border-radius: 10px 10px 0 0;
  padding: 15px 25px;
}
.dbSkillTab .nav-item .nav-link.active {
  background-color: #4264d0;
  color: #fff;
}
.tableCustmization th {
  color: #18357a !important;
  font-size: 14px;
  font-weight: 500;
  border-bottom-color: #e7ebf2 !important;
}
.tableCustmization.table td {
  font-size: 14px;
  color: #5b6680;
}
.tableCustmization {
  border-color: #e7ebf2 !important;
}
.tableCustmization th.worldBreak {
  width: 10px;
  word-break: break-all;
  font-size: 24px !important;
  padding: 0 20px !important;
  border-right: 1px #dee2e6 solid;
  color: #5b6680 !important;
  font-weight: 400;
  vertical-align: middle;
}

.week_picker {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.week_picker label {
  font-size: 1.05rem;
}

.week_picker input {
  padding: 5px;
  border: 1px solid gray;
  border-radius: 8px;
}

.week_picker p {
  border: 1px solid grey;
  padding: 8.5px 8px;
  position: relative;
  top: 8px;
  border-radius: 8px;
  font-size: 1rem;
  color: #4264d0;
  font-weight: 500;
}

.option_cls {
  text-transform: capitalize;
}

.css-e47596-MuiDateRangeCalendar-root > div:first-child {
  display: none !important; 
}

