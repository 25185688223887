.custom_dropdown {
  position: relative;
  /* width: 250px; */
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.selected_option {
  padding: 10px;
  background-color: white;
  /* my css */
  opacity: 0.75;
  font-size: 0.95rem;
}

.arrow_icon {
  margin-left: 8px; /* Space between text and arrow */
  transition: transform 0.2s ease; /* Smooth rotation */
  /* my css */
  float: right;
}

.options {
  display: block; /* Always visible when open */
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 10;
}

.option {
  padding: 10px;
  display: flex;
  align-items: center;
  /* my css */
  font-size: 0.95rem;
  /* justify-content: space-between; */
}

.option:hover {
  background-color: #f0f0f0;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 10px;
}
