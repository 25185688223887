.therapy-card {
  width: 100%;
  min-width: 410px;
  /* height: 431px; */
  border-radius: 10px;
  border: none;
  padding: 15px;
}

.text_size {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #5b6680;
}

.label_text {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: #18357a;
}

.discrption_text {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #5b6680;
}

.text_name {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: #5b6680;
}

.text_span {
  font-weight: 400;
}

.card_footer_text {
  cursor: pointer;
  color: #4264d0;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin-left: 15px;
  /* margin-bottom: 15px; */
}

.upper_div {
  display: flex;
  flex-direction: row;
}
.inner_div {
  margin-top: -3px;
}
.lower_div {
  margin-top: -6px;
}

.info_title {
  font-weight: 700;
  color: #18357a;
  font-size: 1.08rem;
}
.info_img {
  width: 30px;
  height: 30px;
  padding-left: 10px;
}
.info_name {
  font-size: 0.85rem;
  font-weight: 500;
  color: #4264d0;
  padding-left: 6px;
}
.info_div {
  padding-left: 3px;
  padding-right: 3px;
  color: #4264d0;
}
.info_date {
  color: #4264d0;
  font-size: 0.9rem;
}
.info_desc {
  color: #4264d0;
  font-size: 0.8rem;
  margin-top: -4px;
  line-height: 10px;
}
.my_line_height {
  line-height: 10px;
}

.codes {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.codes_list {
  list-style: none;
  margin-left: -5px !important;
  padding-left: 0px !important;
}

@media only screen and (min-width: 1200px) {
  .therapy-card {
    min-width: 372px;
    border-radius: 10px;
    padding: 9px;
  }
}
