.checkbox-container {
    height: 40px;
    display: flex;
    align-items: center;
    margin: 2px 0 2px 0;
    flex-grow: 1; 
}

.checkbox-wrapper {
    display: inline-block;
    padding: 5px 10px 5px 0;
}

.checkbox-wrapper input[type='checkbox'] {
    display: none;
}

.checkbox-label {
    display: inline-block;
    padding: 8px 16px;
    background-color: #F5F5F5;
    border-radius: 10px;
    cursor: pointer;
}

.checkbox-wrapper input[type="checkbox"]:checked + .checkbox-label {
    background-color: #E6F8EF;
}

.change-back input {
    background-color: #E6F8EF;
}

.custom-table th, .custom-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.custom-table-border {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    border-bottom: none;
    border-top: none;
    padding-top: 0;
    padding-bottom: 0;
}

.custom-table-border .align-bottom {
    display: flex;
    align-items: end;
}

.dropdown-wide {
    width: 100%;
}

.intake-dropdown select {
    width: 100%;
    display: block;
}

.invisible {
    display: none;
}

strong {
    color: #5B6680;
    font-weight: 700;
}

.space-items input {
    margin-left: 1em;
}

.wider-first-column td:nth-last-child(-n+3) {
    max-width: fit-content;
}
