.active-tab.nav-link.active {
  background: #5955b3 !important;
  color: #ffffff !important;
  border-radius: 10px, 10px, 0px, 0px !important;
  font-weight: 700;
}

.default_tabs {
  color: #5b6680 !important;
  background-color: #f8f9fe !important;
}

.tabs_nav {
  min-width: 160px;
  min-height: 45px;
  border-radius: 10px !important;
  text-align: center;
}

.scheduleBtn{
  border-radius: 10px !important;
  max-width: 199px !important;
  width: 100%;
  font-size: 14px !important;
  height: 46px;
}

.searchIcon{
  top: 50% !important;
  left: 5px;
  transform: translate(5px, -50%);
}

.custom_dropdown{
  border-radius: 10px;
  border:1px solid #ECECEC;
  background-color: #FFFFFF;
}

.events::before{
  content: "";
  height: 100%;
  background: #00BB61;
  width: 4px;
  position: absolute;
  border-radius: 20px;
  left:0;
  top:0
}

.modalDetails{
  width: 100%;
  min-width: 371px;
  border-radius: 10px;
}

.clinical_documentation p{
font-size: 13px;
font-weight: 600 !important;
color:#4264D0 !important;
width: 100% !important;
}

.clinical_documentation {
  width: 100%!important;
  white-space: nowrap;
}

.custom-modal {
  max-width: 800px !important; /* Adjust this value as needed */
  margin: auto !important;
}

.notes_close_btn{
min-width: 199px;
height: 46px;
gap: 10px;
border-radius: 10px;
background:transparent;
color:#5B6680;
border:1px solid #5B6680
}

.notes_create_btn{
  min-width: 199px;
  height: 46px;
  gap: 10px;
  border-radius: 10px;
  background: #4264D0;
  }

  .profile-image {
    width: 50px;
    height: 50px;
  }
  
  .profile-placeholder {
    width: 50px;
    height: 50px;
    font-size: 24px;
    background-color: #3f4a9c;
  }