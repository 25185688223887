.cardInside {
    height: auto !important;

}


.firstChild:first-child {
    height: auto !important;
    width: auto !important;

}