html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

* {
  outline: none;
}

ul {
  list-style: none;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

.modal-header .btn-close {
  font-size: 14px;
  opacity: 1;
}

.darkBlue {
  color: #18357a;
}

input,
select {
  height: 44px;
  font-size: 14px !important;
}

.curser-pointer {
  cursor: pointer;
}

.fs-small {
  font-size: 10px !important;
}

.btnSize {
  font-size: 14px !important;
  font-weight: 600 !important;
  height: 44px;
  border-radius: 10px !important;
}
.modal_btnn {
  width: 171px;
  height: 46px;
}
.text-green {
  color: #00bb61 !important;
}

/* reactmodel */
.ReactModal__Content {
  max-width: 750px;
  margin: 0 auto;
}

.ReactModal__Overlay {
  background-color: rgb(0 0 0 / 30%) !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.my-btn-cls {
  width: 80px;
}

.btn.pendingBtn {
  background-color: #eceffa;
  color: #4264d0;
  font-size: 12px;
  font-weight: 600;
}
.btn.closedBtn {
  background-color: #4264d01a;
  color: #8f99b0;
  font-size: 12px;
  font-weight: 600;
}

.btn.completeBtn {
  background-color: #e5f8ef;
  color: #00bb61 !important;
  font-size: 12px;
  font-weight: 600;
}

.btn.inProgressBtn {
  background-color: #e5f8ef;
  color: #fea324 !important;
  font-size: 12px;
  font-weight: 600;
}

.btn.Denied {
  background-color: #fc261b2c;
  color: #ff6760 !important;
  /* background-color: transparent; */
  /* background-color: #8f99b0; */
  /* background-color: #e5f8ef; */
  font-size: 12px;
  font-weight: 600;
}

.headingSec p {
  color: #8f99b0;
  font-size: 12px;
}

/* Navigation Tab UI */

.navTabUI.nav li .nav-link.active {
  color: #fff;
  background-color: #5955b3;
  font-weight: 600;
}

.navTabUI.nav li .nav-link {
  color: #5b6680;
  font-size: 15px;
  height: 46px;
  background-color: #f8f9fe;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8f99b0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8f99b0;
}

.btn.btn-link {
  color: #18357a;
  font-size: 14px;
  font-weight: 600;
}

.btn.btn-primary {
  background-color: #4264d0;
  font-size: 12px;
  font-weight: 600;
  border-radius: 10px;
}

.form-control:focus,
.btn-primary {
  box-shadow: 0 0 transparent !important;
}

.content {
  background-color: #3f4a9c;
  /* background-image: url("./assets/images/bg-login.png"); */
}

.mainBglight {
  background-color: #f5f5f7;
  border-radius: 22px;
  overflow: hidden;
  height: 100%;
}

.main-content {
  overflow: auto;
  height: 88%;
}

.headerSec h1 {
  color: #18357a;
  font-size: 25px;
  font-weight: 700;
}

.badge {
  background-color: #ff6760;
}

.custominp {
  width: 350px !important;
}

.modal_custominp span,
.modal_custominp .form-control {
  background-color: #ececec;
  font-size: 12.9px;
  font-weight: 500;
  border-radius: 10px;
}

.custominp span,
.custominp .form-control {
  background-color: #ececec;
  font-size: 12.9px;
  font-weight: 500;
  border-radius: 50px;
}

.profileMenu {
  color: #18357a;
  font-size: 14px;
  font-weight: 700;
}

.notification .dropdown-menu {
  width: 400px;
  box-shadow: 0px 16px 32px 0px #00000014;
}

.notification .dropdown-menu h4 {
  color: #18357a;
  font-size: 17px;
  font-weight: 700;
}

.notification .dropdown-menu span {
  color: #5b6680;
  font-size: 12px;
}

.notification .dropdown-menu ul li p {
  color: #5b6680;
  font-size: 14px;
}

.notification .dropdown-menu ul li span {
  color: #8f99b0;
}

.notification .dropdown-menu ul li a {
  color: #4264d0;
  font-weight: 600;
  font-size: 12px;
}

.btn.viewNoti,
.btn.viewNoti:hover {
  background-color: #f8f9fe;
  color: #4264d0;
  font-weight: 600;
  font-size: 14px;
}

/* top bar */

.topBar a {
  color: #5b6680;
  font-size: 12px;
}

.topBar a.active path {
  fill: #5955b3;
}

.topBar a.active {
  color: #5955b3;
}

/* table heading */

.headingTable h1 {
  color: #18357a;
  font-size: 22px;
  font-weight: 700;
}

.headingTable .btn-success {
  background-color: #e6f8ef;
  color: #00bb61;
  font-weight: 600;
  font-size: 14px;
}

.gridList .active path {
  fill: #fff;
}

.gridList .active {
  background-color: #4264d0 !important;
  z-index: auto !important;
}

.tableList .table {
  table-layout: fixed;
}

.tableList .table th {
  color: #18357a;
  font-size: 14px;
}

.tableList .table td,
.tableList .table th {
  padding: 0;
}

.tableList .table td {
  padding: 10px 0;
}

.tableList .table td {
  color: #5b6680;
  font-weight: 400;
  vertical-align: middle;
}

.tableList .table td span {
  color: #8f99b0;
}

.modal .modal-dialog {
  transform: none;
  max-width: 707px;
}

.tableUI td p {
  color: #5b6680;
  font-size: 12px;
}

.tableUI .table td {
  color: #18357a;
  font-size: 12px;
}

.dropMenu .btn::after {
  content: none;
}

.comment {
  color: #5b6680 !important;
}

.font-size-12 {
  font-size: 12px;
}

/* buttons */
.ftrBtn {
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  padding: 0.5em 4em 0.5em 4em;
  margin: 10px;
}

.cancelButton {
  background-color: #ffffff;
  border: 1px solid #5b6680;
  color: #5b6680;
}

.blueSubmitButton {
  background-color: #4264d0;
  border: none;
  color: #ffffff;
}

.blueSubmitButton[disabled] {
  background-color: #9ba8d0;
  border: none;
  color: #ffffff;
  cursor: not-allowed;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 100% !important;
  }
}

.ReactModal__Content {
  /* max-width: 900px; */
  margin: 0 auto;
  border-radius: 10px !important;
}

.orageColor {
  color: #ff6760 !important;
}
.greenColor {
  color: #00bb61 !important;
}
.tabingUI .btn.btn-primary {
  background-color: #f8f9fe;
  border: 0;
}
.tabingUI .btn.btn-primary span {
  color: #18357a;
  font-size: 20px;
  font-weight: 700;
}
.tabingUI .fal.fa-plus {
  background-color: #4264d0;
  border-radius: 50px;
  width: 23px;
  height: 23px;
  line-height: 25px;
  font-size: 12px;
}
.blueLightBg {
  background-color: #f5f5f5;
  border-radius: 10px;
  color: #5b6680;
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
  margin-bottom: 10px;
}
.blueLightBg.checked {
  background-color: #e6f8ef;
}

.blueLightBg.checked i {
  color: #00bb61;
}

.greyColor {
  color: #8f99b0;
}

.bgYellowscroll {
  border: 1px #fea324 dashed;
  border-radius: 10px;
  padding: 19px;
  height: 180px;
  display: inline-block;
  background-color: #fea32412;
  margin-top: 10px;
  width: 100%;
}
.modal-dialog .modal-body .scrollMax {
  max-height: 300px;
}

.tableCustmization.table td span {
  /* color: #8F99B0; */
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}
.tableCustmization.table td small {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #00bb61;
}
.BreakWords {
  text-align: center;
  vertical-align: middle;
  writing-mode: vertical-lr;
  text-orientation: upright;
}
.dbt-tracker-table {
  margin-bottom: 180px;
}
.DBT_Table input {
  width: 18px;
  accent-color: #4f64cc;
}
.DBT_Table {
  display: flex;
  cursor: alias;
  align-items: center;
}

/* Custom Table In Patient request */
/* #pills-profile > div > table > tbody > tr:nth-child(3) {
  background: #5955B3;
  border-radius: 5px 0px 0px 0px;
}
#pills-profile > div > table > tbody > tr:nth-child(3) td >h6{
  color: #fff;
  padding: 17px 0px !important;
}
#pills-profile > div > table > tbody > tr:nth-child(3) td{
  color: #fff;
  padding: 17px 0px !important;
} */

.articles li {
  text-decoration: none !important;
  display: block;
  margin-bottom: 0;
  color: #555;
}

.articles .badge {
  font-size: 0.7em;
  padding: 5px 10px;
  line-height: 1;
  margin-left: 10px;
}
.articles .item .image {
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  margin-right: 15px;
}
.articles .active h2 {
  color: #fff;
}
.articles .active {
  background-color: #5955b3;
  color: #fff;
}
textarea {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
  color: rgb(143, 153, 176);
  font-size: 12px;
  width: 100%;
  height: 111px;
}
::-webkit-scrollbar {
  height: 8px; /* height of horizontal scrollbar ← You're missing this */
  width: 8px; /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}

.payment-list-tbl {
  overflow-x: scroll;
}
.payment-list-tbl table.table {
  width: 2000px;
}
.receipt-list-tbl {
  overflow-x: scroll;
}
.receipt-list-tbl table.table {
  width: 1100px;
}
/* Dashboard */
.ul_class {
  position: relative;
  bottom: 20px;
}

.h6_font {
  font-size: 14px !important;
}

.label_text {
  font-size: 14px;
  color: #5b6680;
}

.active_chip {
  background: #e6f8ef !important;
}

.label_checkbox {
  font-weight: 700;
  font-size: 22px;
  line-height: 21px;
  color: #ffffff !important;
}

.label_discription {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #ffffff !important;
}

tr.activeRow {
  background-color: #5955b3;
  border-radius: 5px;
  font-size: 16px;
}

.otp_input {
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 32.5px !important;
  text-align: center;
  color: #18357a;
}

.otp {
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp input {
  font-size: 50px !important;
  font-weight: 400 !important;
  line-height: 32.5px !important;
  color: #18357a;
  width: 58px !important;
  height: 85px !important;
  border-radius: 10px;
  display: flex;
  margin: 10px !important;
  border: 2px solid #ececec;
}

.layout_container {
  padding-left: 110px;
}

.cursor_click {
  cursor: pointer;
}

.bg_search_input {
  background: #e9ecef !important;
  border: none;
}

/* .bg_search_input:focus { */
/* border-color: #0b1d66; */
/* border: none; */
/* } */

.my-span-class {
  border: none;
}

/* 
.my-input-group .bg_search_input:focus .my-span-class {
  border-color: blue;
} */

/* .bg_search_input:focus .my-span-class {
  border-color: blue;
} */

.normalRow {
  display: flex;
  flex-direction: row;
  /* gap: 20px; */
  /* justify-content: space-between; */
  align-items: center;
  width: 100%;
  font-size: 16px;
}

.my-td-class {
  width: 44%;
}

.my-td-snd-cls {
  width: 41%;
}

.my-status-cls {
  position: relative;
  right: 17px;
}

.image_upload {
  width: 117px;
  height: 117px;
  border-radius: 50%;
  background-size: cover;
}

h1 {
  color: #0b1d66 !important;
}

.form_other_field {
  height: 46px !important;
  font-size: 14px !important;
}

.span_label {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #18357a !important;
}

.text_normal {
  font-style: normal !important;
}

.modal-heading {
  color: #18357a;
  font-size: 28px;
  font-weight: 700;
  font-family: "Silka", sans-serif;
}

.modal-heading label {
  font-family: "Silka", sans-serif;
}

.documents-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0;
}

.assessment-modal {
  background-color: white;
  padding: 2rem;
}

.ReactModal__Overlay {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.75);
}

.ReactModal__Content {
  pointer-events: auto;
  max-height: 100vh;
  overflow-y: auto;
  padding: 20px;
  border-radius: 8px;
  background: #fff;
}

