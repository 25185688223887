.main {
  max-width: 700px;
  margin: 5rem auto;
  color: #5f667e;
}

.box {
  display: flex;
  border: 1px solid #e7eaf1;
}

.box:last-child {
  border-top: 0;
}

.left {
  width: 16%;
  display: flex;
  border-right: 1px solid #e7eaf1;
  justify-content: center;
  align-items: center;
}

.left h3 {
  writing-mode: vertical-rl;
  text-orientation: upright;
  letter-spacing: 6px;
  font-weight: 400;
}

.right {
  width: 84%;
}

.check-box {
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  position: relative;
  border-bottom: 1px solid #e7eaf1;
  cursor: pointer;
}

.check-box:last-child {
  border: none;
}

.check-box::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background: #e7eaf1;
  top: 0;
  right: 68px;
}

.check-box input {
  width: 26px;
  accent-color: #4f64cc;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.date_input {
  background-image: url("../assets/images/date.png");
  background-repeat: no-repeat;
  background-position: 98%;
}

.modal-boots .modal-content {
  max-width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.close-modal-button{
  align-items: baseline !important;
  padding: 0px 0px;
}
