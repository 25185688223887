.tableList .table th:last-child {
  width: 350px;
}
label, .label {
  font-size: 14px;
  font-weight: 700;
  color: #18357a;
}
.dateBG {
  color: #18357a;
  font-size: 20px;
  font-weight: 500;
}
.appointMent-list span {
  color: #8f99b0;
  font-size: 12px;
}
.appointMent-list h3 {
  font-size: 14px;
  color: #18357a;
}
.appointMent-list h3 span {
  color: #5b6680;
}
.appointMent-list span p {
  font-weight: 400;
  color: #5b6680;
}
.successBg {
  background-color: #e6f8ef;
  border-left: 5px #00bb61 solid;
  border-radius: 3px;
}
.dangerBg {
  background-color: #ffe8e7;
  border-left: 5px #ff6760 solid;
  border-radius: 3px;
}
.alertBg {
  background-color: #fff7e1;
  border-left: 5px #ffc739 solid;
  border-radius: 3px;
}
.blueBg {
  background-color: #5955b3;
}
.roundedCustom {
  border-radius: 12px;
}
.participants h3 {
  color: #18357a;
  font-weight: 700;
  font-size: 20px;
}
.participants span {
  color: #5b6680;
  font-size: 14px;
}
.participants h6 {
  color: #4264d0;
}
.dischargeTxt {
  color: #ff6760 !important;
}
.participants li i {
  color: #00bb61;
}
#scheduleAppoint h2,
#detailsAppoint h2 {
  color: #18357a;
  font-size: 26px;
  font-weight: 700;
}
#scheduleAppoint .nav li .nav-link {
  color: #5b6680;
  font-size: 15px;
  height: 46px;
}
#scheduleAppoint .nav li .nav-link.active {
  color: #fff;
  background-color: #5955b3;
}
.form-check label {
  color: #5b6680;
  font-weight: 400;
}
.btn.alertSuccess {
  background-color: #e6f8ef;
  color: #5b6680;
}
.btn.alertdisable {
  background-color: #fff0ef;
}
.btn.alertdisable i {
  color: #ff6760;
}
.btn.alertSuccess i {
  color: #00bb61;
}
.btn.alertSecondary {
  color: #5b6680;
}
#scheduleAppoint p {
  color: #5b6680;
  font-size: 14px;
}
#detailsAppoint p span {
  font-weight: 400;
  font-size: 14px;
  color: #8f99b0;
}
#detailsAppoint p {
  color: #5b6680;
  font-size: 14px;
}
