/* inner pale blue cards */
.blue-card {
    background-color: #f8f9fe;
    border-radius: 10px;
    padding: 2em;
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.blue-card * {
    font-size: 12px;
}

.blue-card a {
    text-decoration: none;
    color: #4364D0;
    font-weight: 500;
}

.blue-card .blueSubmitButton {
    padding: 0.5em 1em 0.5em 1em;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap; 
}

.blue-card .bold-blue-text {
    color: #18357A;
    font-weight: bold;
    padding-bottom: 1em;
}

.blue-card .bold-text {
    font-weight: bold;
}

.blue-card img {
    height: 7em;
    width: auto;
}

.blue-card .bold-text,
.blue-card input[type=text],
.blue-card input[type=number],
.blue-card li,
.blue-card span,
.blue-card select,
.blue-card table,
.blue-card table span {
    color: #5B6680;
}

.blue-card .small-list-img {
    height: 2em;
}

.blue-card .text-button {
    background: none;
    color: #8F99B0;
    border: none;
    font: inherit;
    cursor: pointer;
}

.blue-card .password-toggle-button {
    background-color: white;
    border: 1px solid #dee2e6;
    border-left: none;
    color: inherit;
}

.blue-card .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.blue-card label {
    color: #8F99B0;
    font-weight: normal;
}

.blue-card .divider {
    border: 1px solid #DADEE6;
}

.blue-card .logo-img {
    padding: 1em;
    height: 5em
}

.blue-card .one-col-display {
    display: block;
}

.blue-card .text-right {
    text-align: end;
}

.blue-card th {
    width: 90%;
    padding: 1em 0 0 0;
}

.blue-card td {
    padding: 1em 0 1em 0;
}

.blue-card li {
    font-size: 10px;
    margin-left: 1em;
}

.blue-card li::marker {
    color: #4364D0;
    font-size: 16px;
}


.blue-card ul {
    list-style-type: disc;
    padding-top: 1em
}

.id-card-container {
    padding: 1em;
    display: flex;
    justify-content: space-around;
}

.id-card {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers content horizontally */
    text-align: center; /* Centers text */
}

.id-card img {
    height: 125px;
    width: 100%;
}
.cards-container {
    display: flex;
}

.profile-img {
    width: 5em;
}

.profile-name {
    margin-bottom: 0;
}

select {
    border-radius: 5px;
    border: 1px solid #EFEFF1;
    padding: 0 5px 0 5px;
}

.small-text {
    font-size: 10px;
}

/* top tab bar */
.tabBar {
    border-radius: 5px;
    width: 100%;
    background-color: #f8f9fe;
}
.tabBar a{
    color: #5B6680;
    display: block;
    font-size: 12px;
    padding: 1em;
}
.tabBar .col{
    padding: 0;
}

.tabBar a.active {
    background-color: #5955B3;
    border-radius: 5px;
    color: #FFFFFF;
}  

.upload-link-button {
    background: none;
    border: none;
    text-decoration: none;
    color: #4364D0;
    font-weight: 500;
}

.whitebox-footer-divider {
    border-top: 1px solid #F6F6F6;
    margin: 0px;
}