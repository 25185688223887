.side_agent {
  background: #fff;
  padding: 0px 0px;
  min-height: 100%;
}
.agent_search {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 15px;
  height: 50px;
  padding: 40px 0px;
}
.btn_agent {
  background: none;
  padding: 10px 12px;
}
.btn_agent:hover {
  outline: none !important;
  box-shadow: none !important;
}
.nav-tabs {
  padding-bottom: 1px !important;
  display: flex;
  gap: 25px;
}
.agent_category .btn_primary {
  height: 35px;
  width: 100%;
  color: var(--Gray-1, #808282);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: transparent;
  justify-content: left;
  padding: 10px 5px;
}
.agent_category {
  display: flex;
  align-items: center;
  gap: 15px;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 1rem;
}
.agent_category .dropdown {
  width: 100%;
}
.agent_category .dropdown-menu.show {
  display: block;
  width: 100%;
}
.agent_item_list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #e7ebf2;
}
.active_agent {
  background: #5955b3;
  color: #fff;
}
.agent_name {
  display: flex;
  align-items: center;
  gap: 15px;
  overflow: auto;
}
.agent_name h5 {
  color: #5955b3;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.user_text {
  color: #ffffff !important;
  font-size: 14px !important;
  line-height: 21px !important;
  font-weight: 400 !important;
}
.user_message {
  color: #ffffff !important;
  font-size: 12px !important;
  line-height: 18px !important;
  font-weight: 400 !important;
}
.agent_name p {
  color: var(--Gray-1, #808282);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.agent_mode p {
  margin: 0;
  color: var(--Gray-1, #808282);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.chat_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 20px;
  background: var(--grayscale-white, #fff);
  padding: 20px;
  gap: 25px;
}
.agent_logo {
  display: flex;
  align-items: center;
  gap: 15px;
}
.chat_agent h5 {
  color: var(--Black, #010404);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.chat_agent p {
  color: var(--Gray-1, #808282);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.chat_bedroom h3 {
  color: var(--Black, #010404);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.chat_bedroom p {
  color: var(--Gray-1, #808282);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 5px;
}
.chat_bedroom p a {
  color: var(--Primary, #549cac);
}

/* ------------ */

.chat-online {
  color: #34ce57;
}

.chat-offline {
  color: #e4606d;
}

.chat-messages {
  display: flex;
  flex-direction: column;
}

.chat-message-left {
  display: flex;
  flex-shrink: 0;
  margin-right: auto;
}
.chat-message-right {
  display: block;
  margin-left: auto;
}

.chat-messages .py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.chat-messages .px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.chat-messages .flex-grow-0 {
  flex-grow: 0 !important;
}
.chat-messages .border-top {
  border-top: 1px solid #dee2e6 !important;
}
.Amelia_text h3 {
  color: var(--Black, #010404);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Amelia_text p {
  color: #18357a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  border-radius: 0px 12px 12px 12px;
  background: #f5f5f7;
  padding: 10px;
  margin: 0;
  max-width: 682px;
}

.message_sidebar_div {
  border-right: 1px solid #e7ebf2;
}

.cht_time .text-muted {
  color: var(--Gray-1, #808282);
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 15px;
}
.you_right {
  display: flex;
  gap: 10px;
  justify-content: end;
}
.right_text .text-muted {
  color: var(--Gray-1, #808282) !important;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.you_right h3 {
  color: var(--Black, #010404);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.right_msg {
  text-align: justify;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  border-radius: 12px 0px 12px 12px;
  background: #5955b3;
  margin-top: 5px;
  max-width: 673px;
  display: block;
  float: inline-end;
  width: -webkit-fill-available;
}
.chat_footer img {
  position: absolute;
  right: 10px;
  top: 10px;
}
.date_text {
  color: #5b6680;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.header_border {
  border-bottom: 1px solid #e7ebf2;
}

.chat_footer .form-control {
  background: var(--White-color, #fff);
  padding: 15px;
  border-radius: 10px !important;
  border: 1px solid #ececec;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none !important;
}

.btn_div {
  border-radius: 10px;
  width: 199px;
  background-color: #e6f8ef;
  padding: 9px, 10px, 9px, 10px;
  height: 34px;
  border: none;
}

.kit_icon {
  color: #fff;
}
.default_color {
  color: #5955b3;
}

.btn_text {
  color: #00bb61;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.span_icon {
  padding-left: 10px;
}

.span_pin {
  transform: rotate(45deg);
}
.agent_list-height{
  height: 520px;
  overflow: auto;
}

.default-chat-box{
  display: flex;
  justify-content: center;
}
.chat-messages-default-center{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 420px;
}
.users-list-css{
  height: 330px;
  overflow: auto;
}
.heading-close-button{
  display: flex;
  justify-content: end;
  gap: 33%;
  text-align: center;
  margin-bottom: 30px;

}
.button-css-All{
  margin-top: 40px !important;
}

.user-input-group{
margin-bottom: 30px;
}
.cursor-pointer{
  cursor: pointer !important;
}

.custom-send-button {
  background-color: transparent; /* Set the initial background color */
  transition: background-color 0.3s ease; /* Smooth transition for background color change */
}

.custom-send-button:hover {
  background-color : #5955B3 ; /* Change background color on hover */
  color: white !important;
}