button:disabled {
    background-color: #F5F5F5;
    border: none;
    color: #8F99B0;
}

.cardPaitent .date{
    color: #8F99B0;
    font-size: 14px;
}

.cardPaitent .fa-check-circle {
    color: #4364D0;
}

.cardPaitent h2, 
.cardPaitent h3, 
.cardPaitent h4 {
    font-family: 'Silka', sans-serif;
    font-weight: 700;
    color: #18357A;
}

.cardPaitent h2 {
    font-size: 28px;
}
.select-patient {
    text-align: center;
    padding-top: 200px;
}
.cardPaitent h3 {
    font-size: 22px;
}

.cardPaitent h4 {
   font-size: 1rem;
   text-overflow: ellipsis;
   overflow-x: hidden;
}

.cardPaitent h6,
.cardPaitent .grey-text {
    color: #5B6680;
    font-size: 14px;
}

.cardPaitent h6 span {
    font-weight: 400;
}

.cardPaitent .horizontal-divider {
    border-bottom: 1px solid #dee2e6; 
}

.cardPaitent input,
.cardPaitent select,
.cardPaitent textarea {
    color: #8F99B0;
    caret-color: grey;
}

.cardPaitent label {
    font-size: 14px;
    color: #18357A;
    font-weight: 700;
}

.cardPaitent label span {
    color: #5B6680;
    font-weight: 400;
}

.cardPaitent li {
    display: flex;
    align-items: center;
}

.cardPaitent span {
    color: #5B6680;
    font-size: 14px;
    font-weight: 400;
}

.cardPaitent p {
    font-size: 12px;
    color: #5B6680;
    margin: 0;
}

.cardPaitent .sideBar {
    height: 100%;
    border-right: 1px solid #DADEE6;
    overflow-y: auto;
}

.sideBar span:hover {
    text-shadow: 2px 2px 6px rgba(189, 184, 184, 0.4);
}


.cardPaitent .sideBar ul {
    list-style: none;
    color: #5B6680;
    padding: 1em;
}

.cardPaitent .sideBar li {
    padding: 1em;
    display: flex;
    align-items: top;
}

.cardPaitent .sideBar span {
    font-size: 14px;
    color: #18357A;
    font-weight: 400;
}

.sideBar span a {
    font-size: 14px;
    color: #18357A;
    font-weight: 700;
}

.cardPaitent .text-button {
    background: none;
    color: #4264D0;
    border: none;
    font-family: 'Silka', sans-serif;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
}

.cardPaitent .vertical-divider {
    border-left: 1px solid #dee2e6; 
}

.cardPaitent .end-divider {
    border-right: 1px solid #dee2e6; 
}

.cardPaitent ul {
    padding: 0;
}

.fa-ellipsis-h {
    color: #5B6680;
}

.moreDetails{
    font-size: 14px;
    color: #4264D0;
    font-weight: 700;
}

.page-item {
    opacity: 0.7; 
}

.page-item.completed,
.page-item.current {
    opacity: 1; /* Opacity for completed pages */
}

