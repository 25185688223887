.checkbox-text {
    margin-left: 5px;
}

.dischargeForm td {
    color: #5B6680;
}

.dischargeForm h4 {
    padding: 1em 0 1em 0;    
}

.dischargeForm input {
    margin: 5px 0 5px 0;
    color: #5B6680;
    padding: 1em;
}

.dischargeForm label {
    padding: 5px 0 0 0
}

/* //having an issue with too much margin, this is here to negate it */
.nomargin {
    margin-top: -2px;
}