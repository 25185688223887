.flex-row {
  display: flex;
  align-items: center;
}

.request-button {
  display: contents;
}

.schedule label {
  font-size: 12px;
  font-weight: 700;
  color: #18357a;
  margin-right: 1%;
}

.schedule label span {
  font-size: 30px;
  font-weight: 700;
  color: #18357a;
}

.page_title {
  color: #18357a;
  font-size: 22px;
  font-weight: 700;
}

.btn-success {
  background-color: #e6f8ef;
  color: #00bb61;
  font-weight: 600;
  font-size: 14px;
}

.modal-custom {
  max-width: 700px;
  max-height: 700px;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.appointment-link {
  font-size: 12px;
}

.session-assessment {
  font-size: 22px;
  font-weight: 700;
  color: #18357a;
}

.btn-assessment {
  border-radius: 5px;
  background-color: #4264d0;
  color: #ffffff;
  border: none;
}

.cancelButton {
  background-color: #ffffff;
  border: 1px solid #5b6680;
  color: #5b6680;
  border-radius: 5px;
}
.request-appoinment {
  color: #18357a;
  font-size: 28px;
  font-weight: 700;
  font-family: "Silka", sans-serif;
}
.select-request {
  margin: auto;
}
.available-btn {
  color: #00bb61;
  font-size: 14;
  font-weight: 700;
}
.custom {
  width: 100% !important;
  background-color: #f5f5f5 !important;
}

/* test */

/* .fc-event-main {
  border-left: 3px #00BB61 solid;
  padding-left: 5px !important;
} */

a.fc-event.fc-event-start.fc-event-end.fc-event-past.fc-timegrid-event.fc-v-event {
  background-color: #e6f8ef;
  border: none;
}

.fc .fc-view-harness {
  position: relative;
}

.fc .fc-view-harness-active > .fc-view {
  position: absolute;
  z-index: 0;
}

.btn_primary {
  display: flex;
  width: 100%;
  justify-content: end;
  height: 100%;
  position: relative;
  top: 40px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: #4264d0;
  cursor: pointer;
}

.fc .fc-timegrid-slot-label {
  vertical-align: middle;
  font-size: 12px;
  color: #8f99b0;
  font-weight: 400px;
}
.calendar-range {
  width: 11% !important;
  position: relative;
  top: 40px;
  left: 20%;
}

/* new css */
.span_text {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #5b6680;
}

.appointments-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

 .fc-event p{
    display: inline-block;   
    width: 20ch;             
    white-space: nowrap;         
    overflow: hidden;           
    text-overflow: ellipsis; 
}
.schedule h3{
  display: inline-block;   
    width: 20ch;             
    white-space: nowrap;         
    overflow: hidden;           
    text-overflow: ellipsis; 
}
 