.notifications-card {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 2em;
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin: 1em;
    min-height: 50vh;
    width: 100%;
}

.notifications-card * {
    font-size: 14px;
}

.notifications-card a,
.modal-content a {
    text-decoration: none;
    color: #4364D0;
    font-weight: 500;
}

.notifications-card .assigner-text,
.modal-content .assigner-text {
    font-weight: 500;
}

.notifications-card .blue-dot {
    width: 5px;
    height: 5px;
    background-color: #4364D0;
    border-radius: 50%;
    margin-right: -5px /*offsets the padding from the profile photo so everything remains aligned verticalled */
}

.notifications-card .blueSubmitButton,
.modal-content .blueSubmitButton {
    padding: 0.5em 1em 0.5em 1em;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap; 
}

.notifications-card h2,
.modal-content h2 {
    color: #18357A;
    font-size: 22px;
    font-weight: 700;
    font-family: 'Silka', sans-serif;
}

.notifications-card .blue-text,
.modal-content .blue-text {
    color: #18357A;
    font-family: 'Silka', sans-serif;
}

.notifications-card .bold-text,
.modal-content .bold-text {
    font-weight: bold;
}

.notifications-card .dropdown {
    padding: 0.375rem 0.375rem;
    margin-left: 1em;
    border-radius: 10px;
}

.notifications-card .dropdown, 
.notifications-card span,
.notifications-card .text-button,
.modal-content .text-button,
.modal-content .grey-text,
.modal-content span  {
    color: #5B6680;
}

.notifications-card .horizontal-divider,
.modal-content .horizontal-divider {
    border-bottom: 1px solid #dee2e6; 
}

.notifications-card .light-grey-text,
.modal-content .light-grey-text {
    color: #8F99B0;
}

.notifications-card .profile-img {
    height: 4em;
    width: 100%;
}

.notifications-card .small-list-img,
.modal-content .small-list-img {
    height: 2em;
    padding: 0 1em 0 1em
}

.notifications-card .smaller-text,
.modal-content .smaller-text {
    font-size: 10px;
}

.notifications-card .text-button,
.modal-content .text-button {
    background: none;
    border: none;
    font: inherit;
    cursor: pointer;
}

.notifications-card th {
    width: 90%;
    padding: 1em 0 0 0;
}

.notifications-card td {
    padding: 1em 0 1em 0;
}


/* notifications modal */
/* 
  
.modal {
    position: fixed;
    top: 100px;
    left: 600px;
    display: block;
    z-index: 1050;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.modal-dialog {
    margin: 0;
}

.modal-content {
    border: none;
}

.modal-header {
    padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.modal-title {
    margin: 0;
}

.modal-body {
    padding: 10px;
}

.modal-footer {
    padding: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.close {
    padding: 10px;
    background: none;
    border: none;
    cursor: pointer;
} */