.assignmentList .btn {
    color: #4264D0;
    font-weight: 600;
    font-size: 12px;
}
.assignmentList span {
    color: #8F99B0;
    font-size: 12px;
}
.assignmentList span {
    color: #8F99B0;
    font-size: 12px; 
}
.assignmentList ul{
    padding: 0 !important;
}
.headingSec h2 {
    color: #18357A;
    font-size: 26px;
    font-weight: 700;
}
.dropMenu i{
    color: #8F99B0;
}
.dropMenu .dropdown-menu {
    box-shadow: 0 0 8px #e6e6e6;
}
.dropMenu .dropdown-menu li a{
    color: #5B6680;
    font-size: 14px;
}
.custominp span, .custominp .form-control {
    background-color: #ECECEC;
    font-size: 12.9px;
    font-weight: 500;
    border-radius: 8px;
}
.patientList span {
    font-size: 12px;
    color: #5B6680;
}
.range-slider .range-slider__range {
    accent-color: #5955B3;
    height: 20px;
}
/* .range-slider .range-slider__range {
    -webkit-appearance: none;
    width: 100%;
    height: 20px;
    background: #5955B3;
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 40px;
  }
  
  .range-slider .range-slider__range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #fff;
    box-shadow:2px 3px 5px #4b4b4b;
    cursor: pointer;
    border-radius: 40px;
  }
  .range-slider .range-slider__range::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #fff;
    cursor: pointer;
  } */